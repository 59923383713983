import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container mx-auto px-4 py-10 mt-14">
      <section className="text-center mb-10 font-quicksand">
        <h1 className="text-4xl font-bold text-blue-600">About Us</h1>
        <p className="text-lg mt-2 text-gray-600">
          "Where Experience Meets Innovation in Logistics"
        </p>
      </section>

        <section className="bg-white shadow-md p-6 mb-10 font-quicksand border-2 rounded-tl-3xl rounded-br-3xl border-orange-500">
          <h2 className="text-2xl font-semibold text-gray-800">Who We Are</h2>
          <p className="mt-4 text-gray-600 leading-relaxed font-semibold">
            Founded in 2013,{" "}
            <span className="font-bold text-orange-600">
              Sk India Marine Services
            </span>{" "}
            is your trusted partner in the export and import logistics sector,
            we blend years of industry expertise with modern solutions to offer
            comprehensive freight forwarding and custom house agency services.
          </p>
          <p className="mt-4 text-gray-600 leading-relaxed font-semibold">
            Founded by two visionary partners,{" "}
            <span className="font-bold text-orange-600">
              Mr. Ravindra Pandurang Kank
            </span>{" "}
            and{" "}
            <span className="font-bold text-orange-600">
              Mrs. Rajeshri Ravindra Kank{" "}
            </span>
            our company has grown steadily over the years, adapting to the
            changing needs of our clients and the evolving logistics landscape.
          </p>

          <p className="mt-4 text-gray-600 leading-relaxed font-semibold">
            At{" "}
            <span className="font-bold text-orange-600">
              Sk India Marine Services
            </span>
            , we thrive on our values of integrity, efficiency, and
            customer-centricity. Whether it's seamless customs clearance,
            reliable freight management, or innovative project cargo solutions,
            we deliver excellence at every step.
          </p>

          <p className="mt-4 text-gray-600 leading-relaxed font-semibold">
            Our mission is simple: to simplify global trade for businesses by
            offering superior services that ensure timely delivery, cost
            efficiency, and compliance. With us, your cargo is not just shipped;
            it's handled with care and expertise.
          </p>
        </section>

      <ScrollAnimation animateIn="fadeIn">
        <section className="bg-white shadow-md p-6 mb-10 font-quicksand border-2 rounded-tl-3xl rounded-br-3xl border-orange-500">
          <h2 className="text-2xl font-semibold text-gray-800">
            Main Pillars of Success
          </h2>
          <ul className="mt-4 text-gray-600 list-disc list-inside font-semibold">
            <li>Exceptional Expertise</li>
            <li>Customer Focus</li>
            <li>Reliable Transporters & Customers</li>
            <li>Advanced Solutions</li>
          </ul>
        </section>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn">
        <section className="bg-white shadow-md p-6 mb-10 font-quicksand  border-2 rounded-tl-3xl rounded-br-3xl border-orange-500">
          <h2 className="text-2xl font-semibold text-gray-800">Our Goals</h2>
          <ul className="mt-4 text-gray-600 list-disc list-inside space-y-2 font-semibold">
            <li>
              Simplify Global Trade: Streamline the logistics process to empower
              businesses in the export and import sectors.
            </li>
            <li>
              Achieve Excellence in Service: Deliver reliable, timely, and
              efficient solutions for our clients worldwide.
            </li>
            <li>
              Sustain Growth with Innovation: Embrace technology and new
              methodologies to stay ahead in the logistics industry.
            </li>
            <li>
              Build Long-Term Relationships: Foster trust and satisfaction by
              exceeding client expectations consistently.
            </li>
            <li>
              Promote Sustainable Practices: Ensure environmentally conscious
              operations, from packaging to transportation.
            </li>
          </ul>
        </section>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn">
        <section className="bg-white shadow-md p-6 mb-10 font-quicksand border-2 rounded-tl-3xl rounded-br-3xl border-orange-500">
          <h2 className="text-2xl font-semibold text-gray-800">Our Values</h2>
          <ul className="mt-4 text-gray-600 list-disc list-inside space-y-2 font-semibold">
            <li>
              <span className="font-semibold text-gray-800">Integrity:</span>{" "}
              Operate with honesty, transparency, and accountability in
              everything we do.
            </li>
            <li>
              <span className="font-semibold text-gray-800">
                Customer-Centric Approach:
              </span>{" "}
              Place our clients at the heart of every decision, ensuring
              personalized and efficient service.
            </li>
            <li>
              <span className="font-semibold text-gray-800">Innovation:</span>{" "}
              Continuously adapt and evolve to provide cutting-edge logistics
              solutions.
            </li>
            <li>
              <span className="font-semibold text-gray-800">Excellence:</span>{" "}
              Strive for perfection in every shipment, every delivery, and every
              interaction.
            </li>
            <li>
              <span className="font-semibold text-gray-800">Teamwork:</span>{" "}
              Foster collaboration within our team and with clients to achieve
              shared goals.
            </li>
            <li>
              <span className="font-semibold text-gray-800">
                Sustainability:
              </span>{" "}
              Commit to eco-friendly practices that protect the environment
              while supporting global trade.
            </li>
          </ul>
        </section>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn">
        <section className="bg-white shadow-md p-6 mb-10 font-quicksand border-2 rounded-tl-3xl rounded-br-3xl border-orange-500">
          <h2 className="text-2xl font-semibold text-gray-800">
            Why Choose Us?
          </h2>
          <ul className="mt-4 text-gray-600 list-disc list-inside space-y-2 font-semibold">
            <li>
              <span className="font-semibold text-gray-800">
                Proven Expertise:
              </span>{" "}
              Since 2013, our leadership team has deep knowledge and insight
              into the logistics industry.
            </li>
            <li>
              <span className="font-semibold text-gray-800">
                Comprehensive Solutions:
              </span>{" "}
              From customs clearance to door-to-door delivery, we offer a
              complete range of services under one roof.
            </li>
            <li>
              <span className="font-semibold text-gray-800">
                Tailored Services:
              </span>{" "}
              We understand that every client’s needs are unique, so we
              customize our services to suit your business goals.
            </li>
            <li>
              <span className="font-semibold text-gray-800">
                Customer First:
              </span>{" "}
              Your satisfaction is our priority. We go above and beyond to
              ensure your shipments are handled with care and delivered on time.
            </li>
            <li>
              <span className="font-semibold text-gray-800">
                Reliability and Precision:
              </span>{" "}
              With skilled professionals and a strong network, we ensure
              efficient, seamless, and error-free operations.
            </li>
            <li>
              <span className="font-semibold text-gray-800">
                Modern Infrastructure:
              </span>{" "}
              Equipped with state-of-the-art facilities, including bonded
              warehousing and cold storage, we handle every kind of cargo
              expertly.
            </li>
            <li>
              <span className="font-semibold text-gray-800">
                Transparent Communication:
              </span>{" "}
              Stay informed at every stage of your shipment, with regular
              updates and dedicated support.
            </li>
            <li>
              <span className="font-semibold text-gray-800">
                Global Reach, Local Expertise:
              </span>{" "}
              Whether it’s an international shipment or local transportation, we
              combine global standards with regional knowledge.
            </li>
          </ul>
        </section>
      </ScrollAnimation>
    </div>
  );
};

export default About;
