import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import BG_VIDEO from "../assets/bg-video.mp4";

import aboutus from "../assets/aboutus.jpg";
import values from "../assets/values.jpg";
import goals from "../assets/goals.jpg";
import whyus from "../assets/whyus.jpg";
import CUSTOM_CLEARANCE from "../assets/custome-clearance-image.jpg";
import SEA_FRIEGHT from "../assets/sea-fright.jpg";
import PROJECT_CARGO from "../assets/cargo.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

const Home = () => {
  useEffect(() => {
    document.querySelector("video").playbackRate = 0.5;
  }, []);

  return (
    <div>
        <div className="relative">
          <video
            autoPlay
            loop
            muted
            className="border-none rounded-br-[8.5rem]"
          >
            <source src={BG_VIDEO} type="video/mp4"></source>
          </video>
          <div className="absolute top-0 w-full h-[20rem] lg:h-[35rem] rounded-br-[8.5rem] mt-1 lg:mt-8 lg:flex justify-between">
            <div className="w-full  pt-[3rem] lg:pt-[10rem] px-2 lg:px-10 text-blue-950 leading-4 lg:leading-5">
              <p className="font-quicksand font-bold text-2xl lg:text-5xl animate-slidein300 drop-shadow-lg text-slate-100 lg:text-[#2F4F4F]">
                SK India Marine Services
              </p>
              <p className="font-quicksand font-bold text-md lg:text-2xl mt-3 animate-slidein500 drop-shadow-lg text-slate-100 lg:text-slate-50">
                Your Trusted Partner in Export and Import Services
              </p>
              <p className="font-quicksand font-semibold text-md lg:text-xl mt-4 animate-slidein700 drop-shadow-lg text-slate-50 lg:text-slate-50">
                Boost global business with our highly <br /> recommended trading
                company.
              </p>
            </div>
            {/* <div className="w-full hidden lg:block">
            <img src={BannerImage} className="w-[40rem] h-[30rem]" />
          </div> */}
          </div>
        </div>

      <div className="lg:px-10">
        <ScrollAnimation animateIn="fadeIn">
          <div className="flex flex-col lg:flex-row justify-between px-2 py-2 lg:px-0 lg:py-0 mt-5 gap-5">
            <div className="w-full flex justify-center items-center">
              {/* <div> */}
              <img src={aboutus} className="w-full h-64 lg:h-96 rounded-lg" />
              {/* </div> */}
            </div>
            <div className="w-full flex flex-col justify-center items-center">
              <p className="text-[#006a43] text-lg lg:text-4xl font-bold font-quicksand drop-shadow-lg text-center">
                About{" "}
              </p>
              <p className="text-[#006a43] text-lg lg:text-4xl font-bold font-quicksand text-center drop-shadow-lg">
                {" "}
                SK India Marine
              </p>
              <p className="text-[#006a43] text-md lg:text-lg font-bold font-quicksand text-center mt-5">
                At Sk India Marine Services, we simplify global logistics with
                Since 2013 of combined experience in the export and import
                industry. From seamless customs clearance to reliable freight
                forwarding, we deliver end-to-end solutions tailored to your
                business needs. Backed by decades of expertise, a skilled team,
                and a customer-first approach, we ensure your shipments reach
                their destination with precision and care.
              </p>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
          <div className="flex flex-col-reverse lg:flex-row justify-between px-2 py-2 lg:px-0 lg:py-0 mt-5">
            <section className="bg-white shadow-md p-6 mb-10 font-quicksand rounded-lg hover:shadow-lg">
              <h2 className="text-2xl font-semibold text-gray-800">
                Our Goals
              </h2>
              <ul className="mt-4 text-gray-600 list-disc list-inside space-y-2 font-semibold">
                <li>
                  Simplify Global Trade: Streamline the logistics process to
                  empower businesses in the export and import sectors.
                </li>
                <li>
                  Achieve Excellence in Service: Deliver reliable, timely, and
                  efficient solutions for our clients worldwide.
                </li>
                <li>
                  Sustain Growth with Innovation: Embrace technology and new
                  methodologies to stay ahead in the logistics industry.
                </li>
                <li>
                  Build Long-Term Relationships: Foster trust and satisfaction
                  by exceeding client expectations consistently.
                </li>
                <li>
                  Promote Sustainable Practices: Ensure environmentally
                  conscious operations, from packaging to transportation.
                </li>
              </ul>
            </section>

            <div className="w-full flex justify-center">
              <div>
                <img src={goals} className="w-full h-64 lg:h-96 rounded-lg" />
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
          <div className="flex flex-col lg:flex-row justify-between px-2 py-2 lg:px-0 lg:py-0">
            <div className="w-full flex">
              <div>
                <img src={values} className="w-full h-64 lg:h-96 rounded-lg" />
              </div>
            </div>
            <section className="bg-white shadow-md p-6 mb-10 font-quicksand rounded-lg hover:shadow-lg">
              <h2 className="text-2xl font-semibold text-gray-800">
                Our Values
              </h2>
              <ul className="mt-4 text-gray-600 list-disc list-inside space-y-2 font-semibold">
                <li>
                  <span className="font-semibold text-gray-800">
                    Integrity:
                  </span>{" "}
                  Operate with honesty, transparency, and accountability in
                  everything we do.
                </li>
                <li>
                  <span className="font-semibold text-gray-800">
                    Customer-Centric Approach:
                  </span>{" "}
                  Place our clients at the heart of every decision, ensuring
                  personalized and efficient service.
                </li>
                <li>
                  <span className="font-semibold text-gray-800">
                    Innovation:
                  </span>{" "}
                  Continuously adapt and evolve to provide cutting-edge
                  logistics solutions.
                </li>
                <li>
                  <span className="font-semibold text-gray-800">
                    Excellence:
                  </span>{" "}
                  Strive for perfection in every shipment, every delivery, and
                  every interaction.
                </li>
                <li>
                  <span className="font-semibold text-gray-800">Teamwork:</span>{" "}
                  Foster collaboration within our team and with clients to
                  achieve shared goals.
                </li>
                <li>
                  <span className="font-semibold text-gray-800">
                    Sustainability:
                  </span>{" "}
                  Commit to eco-friendly practices that protect the environment
                  while supporting global trade.
                </li>
              </ul>
            </section>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
          <div className="flex flex-col-reverse lg:flex-row justify-between px-2 py-2 lg:px-0 lg:py-0">
            <section className="bg-white shadow-md p-6 mb-10 font-quicksand rounded-lg hover:shadow-lg">
              <h2 className="text-2xl font-semibold text-gray-800">
                Why Choose Us?
              </h2>
              <ul className="mt-4 text-gray-600 list-disc list-inside space-y-2 font-semibold">
                <li>
                  <span className="font-semibold text-gray-800">
                    Proven Expertise:
                  </span>{" "}
                  Since 2013, our leadership team has deep knowledge and insight
                  into the logistics industry.
                </li>
                <li>
                  <span className="font-semibold text-gray-800">
                    Comprehensive Solutions:
                  </span>{" "}
                  From customs clearance to door-to-door delivery, we offer a
                  complete range of services under one roof.
                </li>
                <li>
                  <span className="font-semibold text-gray-800">
                    Tailored Services:
                  </span>{" "}
                  We understand that every client’s needs are unique, so we
                  customize our services to suit your business goals.
                </li>
                <li>
                  <span className="font-semibold text-gray-800">
                    Customer First:
                  </span>{" "}
                  Your satisfaction is our priority. We go above and beyond to
                  ensure your shipments are handled with care and delivered on
                  time.
                </li>
                <li>
                  <span className="font-semibold text-gray-800">
                    Reliability and Precision:
                  </span>{" "}
                  With skilled professionals and a strong network, we ensure
                  efficient, seamless, and error-free operations.
                </li>
                <li>
                  <span className="font-semibold text-gray-800">
                    Modern Infrastructure:
                  </span>{" "}
                  Equipped with state-of-the-art facilities, including bonded
                  warehousing and cold storage, we handle every kind of cargo
                  expertly.
                </li>
                <li>
                  <span className="font-semibold text-gray-800">
                    Transparent Communication:
                  </span>{" "}
                  Stay informed at every stage of your shipment, with regular
                  updates and dedicated support.
                </li>
                <li>
                  <span className="font-semibold text-gray-800">
                    Global Reach, Local Expertise:
                  </span>{" "}
                  Whether it’s an international shipment or local
                  transportation, we combine global standards with regional
                  knowledge.
                </li>
              </ul>
            </section>
            <div className="w-full flex justify-center">
              <div>
                <img src={whyus} className="w-full h-64 lg:h-96 rounded-lg" />
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>

      <ScrollAnimation animateIn="fadeIn">
        <div className="bg-orange-100 rounded-tl-[5.5rem] lg:rounded-tl-[8.5rem] py-10">
          <p className="font-quicksand font-semibold text:lg lg:text-3xl mt-4 animate-slidein700 text-center drop-shadow-lg">
            Delivering tailored solutions to drive your success.
          </p>
          <div className="flex flex-col lg:flex-row justify-center items-center gap-10 mt-10">
            <div className="max-w-xs overflow-hidden bg-white border-2 rounded-tl-3xl rounded-br-3xl border-blue-700 shadow-md transform transition-all duration-500 hover:shadow-lg hover:scale-105 relative group">
              <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-white opacity-0 transition-opacity duration-500 group-hover:opacity-30 blur-md"></div>
              <div className="p-2">
                <img
                  src={CUSTOM_CLEARANCE}
                  className="w-full h-48 rounded-lg"
                />
              </div>

              <div className="p-6 relative z-10">
                <p className="text-xl font-semibold text-gray-800">
                  Custom Clearance
                </p>
                <p className="mt-2 text-gray-600">
                  Let us handle the complexities of customs for you. Our
                  experienced team ensures smooth clearance while adhering to
                  all legal and regulatory frameworks, minimizing delays and
                  maximizing efficiency.
                </p>
              </div>
            </div>

            <div className="max-w-xs overflow-hidden bg-white border-2 rounded-tl-3xl rounded-br-3xl border-orange-700 shadow-md transform transition-all duration-500 hover:shadow-lg hover:scale-105 relative group">
              <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-white opacity-0 transition-opacity duration-500 group-hover:opacity-30 blur-md"></div>
              <div className="p-2">
                <img src={SEA_FRIEGHT} className="w-full h-48 rounded-lg" />
              </div>
              <div className="p-6 relative z-10">
                <p className="text-xl font-semibold text-gray-800">
                  Sea Freight
                </p>
                <p className="mt-2 text-gray-600">
                  From FCL (Full Container Load) to LCL (Less than Container
                  Load), we offer cost-effective sea freight solutions designed
                  to meet your business needs globally.
                </p>
              </div>
            </div>

            <div className="max-w-xs overflow-hidden bg-white border-2 rounded-tl-3xl rounded-br-3xl border-pink-700 shadow-md transform transition-all duration-500 hover:shadow-lg hover:scale-105 relative group">
              <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-white opacity-0 transition-opacity duration-500 group-hover:opacity-30 blur-md"></div>
              <div className="p-2">
                <img src={PROJECT_CARGO} className="w-full h-48 rounded-lg" />
              </div>
              <div className="p-6 relative z-10">
                <p className="text-xl font-semibold text-gray-800">
                  Project Cargo/Chartering
                </p>
                <p className="mt-2 text-gray-600">
                  Handling oversized or complex shipments is our specialty. Our
                  project cargo and chartering solutions ensure your goods are
                  transported securely and efficiently, no matter the size or
                  challenge.
                </p>
              </div>
            </div>
          </div>
          <p className="text-center flex justify-center mt-5 font-quicksand font-semibold px-10 cursor-pointer">
            <Link to="/services">
              <button className="relative flex items-center px-6 py-3 overflow-hidden font-medium transition-all bg-indigo-500 rounded-md group">
                <span className="absolute top-0 right-0 inline-block w-4 h-4 transition-all duration-500 ease-in-out bg-indigo-700 rounded group-hover:-mr-4 group-hover:-mt-4">
                  <span className="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-white"></span>
                </span>
                <span className="absolute bottom-0 rotate-180 left-0 inline-block w-4 h-4 transition-all duration-500 ease-in-out bg-indigo-700 rounded group-hover:-ml-4 group-hover:-mb-4">
                  <span className="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-white"></span>
                </span>
                <span className="absolute bottom-0 left-0 w-full h-full transition-all duration-500 ease-in-out delay-200 -translate-x-full bg-indigo-600 rounded-md group-hover:translate-x-0"></span>
                <span className="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-white">
                  View More Services
                </span>
              </button>
            </Link>
          </p>
        </div>
      </ScrollAnimation>
    </div>
  );
};

export default Home;
