import React, { useEffect, useState } from "react";
import ParticlesComponent from "./ParticlesComponent";

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
    alert("Thank you for reaching out! We'll get back to you soon.");
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      message: "",
    });
  };

  return (
    <div className="container mx-auto px-4 py-10 font-quicksand mt-14">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
        <div className="bg-white p-6 rounded-lg shadow-lg hidden">
          <h1 className="text-2xl font-bold text-gray-800 mb-6">Contact Us</h1>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-600">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full mt-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="w-full mt-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full mt-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">
                Mobile Number
              </label>
              <input
                type="tel"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                className="w-full mt-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">
                Message
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                className="w-full mt-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-500"
            >
              Submit
            </button>
          </form>
        </div>

        <div class="bg-gray-100 p-6 rounded-lg shadow-lg max-w-3xl mx-auto font-quicksand">
          <h2 class="text-2xl font-bold text-gray-800 mb-4">
            Contact Information
          </h2>
          <div class="space-y-6">
            <div>
              <h3 class="text-lg font-semibold text-blue-600">
                Register Address
              </h3>
              <p class="text-gray-700">
                NEAR SAI BABA MANDIR STATION ROAD, 2ND FLR, ROOM NO-210, Sector
                5 Road, Kopar Khairane, Navi Mumbai, Thane, Maharashtra, 400709
              </p>
            </div>
            <div>
              <h3 class="text-lg font-semibold text-blue-600">
                Office Address
              </h3>
              <p class="text-gray-700">
                Office No-112, Commodity Exchange Building, Sector 19A, Vashi
                APMC Market, Navi Mumbai, Maharashtra, 400703
              </p>
            </div>
            <div>
              <h3 class="text-lg font-semibold text-blue-600">Get in Touch</h3>
              <p class="text-gray-700">
                <strong>Phone:</strong> +91 9594875759
              </p>
              <p class="text-gray-700">
                <strong>Email:</strong> info@skindiamarineservices.com
              </p>
            </div>
          </div>
        </div>

        <div className="text-center md:text-left">
          <p className="mt-4 text-gray-600">
            Reach out to us for any queries, feedback, or support. We're here to
            help you every step of the way!
          </p>

          <div className="h-80 mt-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.6405069214716!2d73.00757657387503!3d19.079537151864056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c1394978006b%3A0x12fd6d16abacf57!2sCommodity%20Exchange!5e0!3m2!1sen!2sin!4v1732547882286!5m2!1sen!2sin"
              width="100%"
              height="300"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
