import React, { useState } from "react";
import Logo from "../assets/logo.png";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrollAtTop, setIsScrollAtTop] = useState(true);

  const location = useLocation();

  window.addEventListener("scroll", () => {
    if (window.scrollY === 0) {
      setIsScrollAtTop(true);
    } else {
      setIsScrollAtTop(false);
    }
  });

  return (
    <nav
      className={`${
        isScrollAtTop && location.pathname == "/"
          ? "lg:bg-transparent"
          : "bg-[#d7fec8] shadow-md"
      } ${
        isOpen ? "bg-[#d7fec8]" : null
      } flex items-center justify-between flex-wrap px-2 lg:px-10 fixed top-0 right-0 left-0 z-10`}
    >
      <div className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-72">
        <Link tp={"/"}>
          <img src={Logo} className="w-100 h-14 lg:h-20 mr-2" alt="Logo" />
        </Link>
      </div>
      <div className="block lg:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
        >
          <svg
            className={`fill-current h-3 w-3 ${isOpen ? "hidden" : "block"}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
          <svg
            className={`fill-current h-3 w-3 ${isOpen ? "block" : "hidden"}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
          </svg>
        </button>
      </div>
      <div
        className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <div className="text-sm lg:flex lg:gap-5">
          <a
            onClick={() => setIsOpen(!isOpen)}
            href="#"
            className="block mt-4 font-quicksand font-semibold text-lg lg:inline-block lg:mt-0 text-white-200 mr-4"
          >
            <Link to="/">Home</Link>
          </a>
          <a
            onClick={() => setIsOpen(!isOpen)}
            href="#"
            className="block mt-4 text-lg font-quicksand font-semibold lg:inline-block lg:mt-0 text-white-200 mr-4"
          >
            <Link to="/services">Services</Link>
          </a>
          <a
            onClick={() => setIsOpen(!isOpen)}
            href="#"
            className="block mt-4 text-lg font-quicksand font-semibold lg:inline-block lg:mt-0 text-white-200 mr-4"
          >
            <Link to="/about">About Us</Link>
          </a>
          <a
            onClick={() => setIsOpen(!isOpen)}
            href="#"
            className="block mt-4 text-lg font-quicksand font-semibold lg:inline-block lg:mt-0 text-white-200 mr-4"
          >
            <Link to="/contact">Contact Us</Link>
          </a>
        </div>
        <div className="w-full flex flex-grow py-5 lg:py-0 items-center lg:w-auto font-quicksand font-semibold gap-3 lg:gap-2 lg:justify-end">
          <a href="tel:+919594875759">
            <i className="fa-solid fa-phone mr-3"></i>+91 9594875759
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
