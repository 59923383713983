import React, { useEffect } from "react";
import CUSTOM_CLEARANCE from "../assets/custome-clearance-image.jpg";
import SEA_FRIEGHT from "../assets/sea-fright.jpg";
import PROJECT_CARGO from "../assets/cargo.jpg";
import FCL from "../assets/fcl.jpg";
import HAZARDEOUS_CARGO from "../assets/cargo-2.jpg";
import DOOR_DELIVERY from "../assets/door.jpg";
import CARGO_INSURANCE from "../assets/cargo-insurance.jpg";
import CUSTOME_WAREHOUSE from "../assets/warehouse.jpg";
import COLD_STORAGE from "../assets/cold-storage.jpg";
import PACKAGE_FACILITY from "../assets/pack.jpg";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="py-16 mt-10">
      <p className="text-3xl font-quicksand text-center drop-shadow-lg font-bold py-5 text-blue-600">
        What We Do Best
      </p>
      <div className="flex flex-wrap justify-center items-center gap-10">
        <div className="card max-w-xs overflow-hidden bg-white border border-gray-200 rounded-xl shadow-md transform transition-all duration-500 hover:shadow-lg hover:scale-105 relative group">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-white opacity-0 transition-opacity duration-500 group-hover:opacity-30 blur-md"></div>
          <img src={CUSTOM_CLEARANCE} className="w-full h-48 rounded-lg" />
          <div className="p-6 relative z-10">
            <p className="text-xl font-semibold text-gray-800 font-quicksand">
              Custom Clearance
            </p>
            <p className="mt-2 text-gray-60 font-quicksand">
              Let us handle the complexities of customs for you. Our experienced
              team ensures smooth clearance while adhering to all legal and
              regulatory frameworks, minimizing delays and maximizing
              efficiency.
            </p>
          </div>
        </div>

        <div className="card max-w-xs overflow-hidden bg-white border border-gray-200 rounded-xl shadow-md transform transition-all duration-500 hover:shadow-lg hover:scale-105 relative group">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-white opacity-0 transition-opacity duration-500 group-hover:opacity-30 blur-md"></div>
          <img src={SEA_FRIEGHT} className="w-full h-48 rounded-lg" />
          <div className="p-6 relative z-10">
            <p className="text-xl font-semibold text-gray-800 font-quicksand">
              Sea Freight
            </p>
            <p className="mt-2 text-gray-60 font-quicksand">
              From FCL (Full Container Load) to LCL (Less than Container Load),
              we offer cost-effective sea freight solutions designed to meet
              your business needs globally.
            </p>
          </div>
        </div>

        <div className="card max-w-xs overflow-hidden bg-white border border-gray-200 rounded-xl shadow-md transform transition-all duration-500 hover:shadow-lg hover:scale-105 relative group">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-white opacity-0 transition-opacity duration-500 group-hover:opacity-30 blur-md"></div>
          <img src={PROJECT_CARGO} className="w-full h-48 rounded-lg" />
          <div className="p-6 relative z-10">
            <p className="text-xl font-semibold text-gray-800 font-quicksand">
              Project Cargo/Chartering
            </p>
            <p className="mt-2 text-gray-60 font-quicksand">
              Handling oversized or complex shipments is our specialty. Our
              project cargo and chartering solutions ensure your goods are
              transported securely and efficiently, no matter the size or
              challenge.
            </p>
          </div>
        </div>

        <div className="card max-w-xs overflow-hidden bg-white border border-gray-200 rounded-xl shadow-md transform transition-all duration-500 hover:shadow-lg hover:scale-105 relative group">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-white opacity-0 transition-opacity duration-500 group-hover:opacity-30 blur-md"></div>
          <img src={FCL} className="w-full h-48 rounded-lg" />
          <div className="p-6 relative z-10">
            <p className="text-xl font-semibold text-gray-800 font-quicksand">
              FCL & LCL Consolidation
            </p>
            <p className="mt-2 text-gray-60 font-quicksand">
              Optimize your shipping strategy with our consolidation services,
              efficiently combining smaller consignments into full loads for
              cost savings and on-time delivery.
            </p>
          </div>
        </div>

        <div className="card max-w-xs overflow-hidden bg-white border border-gray-200 rounded-xl shadow-md transform transition-all duration-500 hover:shadow-lg hover:scale-105 relative group">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-white opacity-0 transition-opacity duration-500 group-hover:opacity-30 blur-md"></div>
          <img src={HAZARDEOUS_CARGO} className="w-full h-48 rounded-lg" />
          <div className="p-6 relative z-10">
            <p className="text-xl font-semibold text-gray-800 font-quicksand">
              Hazardous & Reefer Cargo
            </p>
            <p className="mt-2 text-gray-60 font-quicksand">
              Our specialized handling services guarantee the secure and
              compliant transportation of hazardous goods and
              temperature-sensitive cargo, ensuring safety at every step.
            </p>
          </div>
        </div>

        <div className="card max-w-xs overflow-hidden bg-white border border-gray-200 rounded-xl shadow-md transform transition-all duration-500 hover:shadow-lg hover:scale-105 relative group">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-white opacity-0 transition-opacity duration-500 group-hover:opacity-30 blur-md"></div>
          <img src={DOOR_DELIVERY} className="w-full h-48 rounded-lg" />
          <div className="p-6 relative z-10">
            <p className="text-xl font-semibold text-gray-800 font-quicksand">
              Door-to-Door Delivery
            </p>
            <p className="mt-2 text-gray-60 font-quicksand">
              From pickup to final delivery, we offer seamless door-to-door
              logistics services that eliminate hassle and keep your business
              operations running smoothly.
            </p>
          </div>
        </div>

        <div className="card max-w-xs overflow-hidden bg-white border border-gray-200 rounded-xl shadow-md transform transition-all duration-500 hover:shadow-lg hover:scale-105 relative group">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-white opacity-0 transition-opacity duration-500 group-hover:opacity-30 blur-md"></div>
          <img src={CARGO_INSURANCE} className="w-full h-48 rounded-lg" />
          <div className="p-6 relative z-10"> 
            <p className="text-xl font-semibold text-gray-800 font-quicksand">
              Cargo Insurance & Fumigation
            </p>
            <p className="mt-2 text-gray-60 font-quicksand">
              Protect your cargo from unforeseen risks with our comprehensive
              insurance solutions and fumigation services, ensuring peace of
              mind every step of the way.
            </p>
          </div>
        </div>

        <div className="card max-w-xs overflow-hidden bg-white border border-gray-200 rounded-xl shadow-md transform transition-all duration-500 hover:shadow-lg hover:scale-105 relative group">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-white opacity-0 transition-opacity duration-500 group-hover:opacity-30 blur-md"></div>
          <img src={CUSTOME_WAREHOUSE} className="w-full h-48 rounded-lg" />
          <div className="p-6 relative z-10">
            <p className="text-xl font-semibold text-gray-800 font-quicksand">
              Custom Bonded Warehousing
            </p>
            <p className="mt-2 text-gray-60 font-quicksand">
              Secure storage solutions tailored to meet customs regulations and
              your operational needs, offering flexibility and efficiency for
              your supply chain.
            </p>
          </div>
        </div>

        <div className="card max-w-xs overflow-hidden bg-white border border-gray-200 rounded-xl shadow-md transform transition-all duration-500 hover:shadow-lg hover:scale-105 relative group">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-white opacity-0 transition-opacity duration-500 group-hover:opacity-30 blur-md"></div>
          <img src={COLD_STORAGE} className="w-full h-48 rounded-lg" />
          <div className="p-6 relative z-10">
            <p className="text-xl font-semibold text-gray-800 font-quicksand">
              Cold Storage Facility
            </p>
            <p className="mt-2 text-gray-60 font-quicksand">
              Preserve the integrity of temperature-sensitive goods with our
              advanced cold storage facilities, designed to keep your products
              in perfect condition.
            </p>
          </div>
        </div>

        <div className="card max-w-xs overflow-hidden bg-white border border-gray-200 rounded-xl shadow-md transform transition-all duration-500 hover:shadow-lg hover:scale-105 relative group">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-white opacity-0 transition-opacity duration-500 group-hover:opacity-30 blur-md"></div>
          <img src={PACKAGE_FACILITY} className="w-full h-48 rounded-lg" />
          <div className="p-6 relative z-10">
            <p className="text-xl font-semibold text-gray-800 font-quicksand">
              Packaging & Palletization
            </p>
            <p className="mt-2 text-gray-60 font-quicksand">
              With our expert packaging and palletization solutions, your cargo
              is securely prepared to meet global shipping standards, ensuring
              both protection and operational efficiency.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
