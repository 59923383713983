import React from "react";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="relative pt-8 pb-6 bg-[#d7fec8]">
      <div className="container mx-auto">
        <div className="flex flex-wrap text-left lg:text-left">
          <div className="w-full lg:w-4/12">
            <img src={Logo} className="w-100 h-14 lg:h-20 mr-2" alt="Logo" />
            <h5 className="text-md mt-0 mb-2 text-blueGray-600 font-quicksand font-semibold px-4 italic">
              Your Trusted Partner in Export and Import Services
            </h5>
            <div className="mt-6 lg:mb-0 mb-6 hidden">
              <button
                className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                type="button"
              >
                <i className="fab fa-facebook-square"></i>
              </button>
              <button
                className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                type="button"
              >
                <i className="fab fa-instagram"></i>
              </button>
              <button
                className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                type="button"
              >
                <i className="fab fa-twitter"></i>
              </button>
            </div>
          </div>
          <div className="w-full lg:w-8/12">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-blueGray-500 text-sm font-bold mb-2 font-quicksand">
                  Services
                </span>
                <ul className="list-unstyled">
                  <li className="hover:underline">
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm font-quicksand"
                      href="#"
                    >
                      <Link to="/services">Custom Clearance</Link>
                    </a>
                  </li>
                  <li className="hover:underline">
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm font-quicksand"
                      href="#"
                    >
                      <Link to="/services">Sea Freight</Link>
                    </a>
                  </li>
                  <li className="hover:underline">
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm font-quicksand"
                      href="#"
                    >
                      <Link to="/services">
                        Project Cargo/Chartering
                      </Link>
                    </a>
                  </li>
                  <li className="hover:underline">
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm font-quicksand"
                      href="#"
                    >
                      <Link to="/services">
                        FCL & LCL Consolidation
                      </Link>
                    </a>
                  </li>
                  <li className="hover:underline">
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm font-quicksand"
                      href="#"
                    >
                      <Link to="/services">
                        Hazardous & Reefer Cargo
                      </Link>
                    </a>
                  </li>
                  <li className="block lg:hidden">
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm font-quicksand"
                      href="#"
                    >
                      <Link to="/services">
                        Door-to-Door Delivery
                      </Link>
                    </a>
                  </li>
                  <li className="block lg:hidden">
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm font-quicksand"
                      href="#"
                    >
                      <Link to="/services">
                        Cargo Insurance & Fumigation
                      </Link>
                    </a>
                  </li>
                  <li className="block lg:hidden">
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm font-quicksand"
                      href="#"
                    >
                      <Link to="/services">
                        Custom Bonded Warehousing
                      </Link>
                    </a>
                  </li>
                  <li className="block lg:hidden">
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm font-quicksand"
                      href="#"
                    >
                      <Link to="/services">
                        Cold Storage Facility
                      </Link>
                    </a>
                  </li>
                  <li className="block lg:hidden">
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm font-quicksand"
                      href="#"
                    >
                      <Link to="/services">
                        Packaging & Palletization
                      </Link>
                    </a>
                  </li>
                  <li className="lg:block hidden hover:underline">
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm font-quicksand"
                      href="#"
                    >
                      <Link to="/services">
                        More services
                      </Link>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2 font-quicksand">
                  Office address
                </span>
                <ul className="list-unstyled">
                  <li className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm font-quicksand">
                      <span>Office No-112, Commodity Exchange Building,</span>
                      <span>Sector 19A, Vashi APMC Market,</span>
                      <span>Navi Mumbai, Maharashtra, 400703</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-900" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-blueGray-500 font-semibold py-1 font-quicksand">
              Copyright ©{" "}
              <span id="get-current-year">{new Date().getFullYear()}</span>
              <span
                className="text-blueGray-500 hover:text-gray-800"
              />{" "}
              SKMarine&nbsp;
              <span
                className="text-blueGray-500 hover:text-blueGray-800 font-quicksand"
              >
                India Services
              </span>
              .
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
